<template>
  <Tooltip
    :text="title"
    :left="tooltipLeft"
    :top="tooltipTop"
  >
    <div
      class="multiview-action-button"
      @click="onClick"
    >
      <Icon
        class="multiview-action-button__icon"
        :name="icon"
      />
    </div>
  </Tooltip>
</template>

<script>
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    tooltipLeft: {
      type: Boolean,
      default: false,
    },
    tooltipTop: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {},
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('click');
    };

    return {
      onClick,
    };
  },
};
</script>

<style lang="scss">
.multiview-action-button {
  width: var(--width-medium, 32px);
  min-width: 32px;
  max-width: 32px;
  height: var(--width-medium, 32px);
  min-height: 32px;
  max-height: 32px;
  padding: var(--padding-xxsmall, 4px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-none, 0px);
  border-radius: var(--radius-medium, 4px);
  padding: 8px;
  cursor: pointer;
  fill: #191414;
}
</style>
