<template>
  <Tooltip
    :text="tooltipText"
  >
    <div
      :class="[
        'multiview-pin-button',
        { 'multiview-pin-button--is-pinned': pinned },
      ]"
      @click="onClick"
    >
      <Icon
        class="multiview-pin-button__icon"
        name="pin"
      />
    </div>
  </Tooltip>
</template>

<script>
import { computed } from 'vue';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    pinned: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    pin: {},
    unpin: {},
  },
  setup(props, { emit }) {
    const tooltipText = computed(() => (props.pinned ? 'Unpin event' : 'Pin event'));

    const onClick = () => {
      if (props.pinned) {
        emit('unpin');
        return;
      }
      emit('pin');
    };

    return {
      tooltipText,
      onClick,
    };
  },
};
</script>

<style lang="scss">
.multiview-pin-button {
  width: var(--width-medium, 32px);
  height: var(--width-medium, 32px);
  padding: var(--padding-xxsmall, 4px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-none, 0px);
  border-radius: var(--radius-medium, 4px);
  padding: 8px;
  cursor: pointer;
  fill: #cdcdcd;

  &--is-pinned {
    background: var(--neutral-bg-transparent-dark, rgba(0, 0, 0, 0.50));
  }
}
</style>
